import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentRadioButton.figmaUrl.android} codeUrl={checklists.componentRadioButton.codeUrl.android} checklists={checklists.componentRadioButton.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Radio buttons allow the user to select one option from a dataset.`}</p>
    <br />
    <br />
    <undefined><img {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/android_radiobutton.png",
        "align": "middle",
        "alt": "android radio button"
      }}></img>
      <br></br>
      <br></br></undefined>
    <h2>{`Variants`}</h2>
    <h3><strong parentName="h3">{`Single`}</strong></h3>
    <p>{`Single radio button used for single option value.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var isSelected by remember { mutableStateOf(false) }

LgnRadioButton(
    text = "Radio Button1",
    selected = isSelected,
    onClick = {
    isSelected = !isSelected
  }
)
`}</code></pre>
    <p>{`Here is customization of LgnRadioButton parameters at the `}<inlineCode parentName="p">{`LgnRadioButton`}</inlineCode>{` function:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The text to be displayed on the radio button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A boolean indicating whether the radio button is selected.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onClick`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The callback that is invoked when the radio button is clicked.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`modifier`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Modifier to be applied to the radio button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Modifier`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`enabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A boolean indicating whether the radio button is enabled.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the radio button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LgnRadioButtonDefaults.size`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`colors`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The colors to be used for the radio button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LgnRadioButtonDefaults.colors()`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`interactionSource`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The source of interactions for the radio button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`remember { MutableInteractionSource() }`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3><strong parentName="h3">{`Group`}</strong></h3>
    <p>{`Radio button group used for show multiple option value.`}</p>
    <p>{`Example usage :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var selectedRbGroupValue by remember { mutableStateOf("")}
        LgnRadioButtonGroup(
            selectedValue = selectedRbGroupValue,
            onSelectionChange = {it -> handleOnClickRRBGroup(it)},
            value = listOfRadioButton,
            title = "Radio Button Group"
        )
`}</code></pre>
    <p>{`Here is customization of LgnRadioButton parameters at `}<inlineCode parentName="p">{`LgnRadioButtonGroup`}</inlineCode>{` function:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`selectedValue`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The currently selected value in the radio button group.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The title of the radio button group.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onSelectionChange`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The callback that is invoked when the selection changes in the radio button group.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`value`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The set of values for the radio buttons in the group.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      